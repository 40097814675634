.contacts {
  background: linear-gradient(135deg, #ffffff, #f8f8f8);
  padding: 50px 30px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 50px auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }

  &__title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 25px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__info {
    padding: 35px;
    animation: fadeIn 1s ease-in-out;
  }

  &__hotel-name {
    font-size: 1.6rem;
    color: #34495e;
    margin-bottom: 20px;
  }

  &__address,
  &__phone,
  &__email {
    font-size: 17px;
    color: #555;
    margin: 15px 0;
  }

  a {
    color: $main-color;
    text-decoration: none;
    font-weight: 500;
    display: block;
    transition: color 0.3s, transform 0.3s;

    &:hover {
      color: $main-color-hover;
      transform: scale(1.05);
    }
  }

  // Добавляем анимацию
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}