.video-grid {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center; // Центрирование при одном видео

  .video-container {
    // Для нескольких видео: 2 на строку
    flex: 1 1 calc(50% - 16px);
    
    // Центрирование при одном видео
    &:only-child {
      flex: 0 0 50%; // Ограничиваем ширину до половины контейнера
      margin: 0 auto; // Центрируем по горизонтали
    }

    video {
      width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

// Медиазапрос для экранов шириной меньше 1024px
@media (max-width: 1024px) {
  .video-grid {
    margin-left: 12px;
    margin-right: 12px;
    .video-container {
      flex: 1 1 100%; // Видео занимает всю ширину
      &:only-child {
        flex: 1 1 100%; // Единственное видео также занимает всю ширину
      }
    }
  }
}