.customersList {
    margin: 20px;
    font-family: Arial, sans-serif;
    &Heading {
        margin-bottom: 20px;
    }
}



table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    position: sticky; /* Фиксируем заголовки */
    top: 0; /* Позиционируем заголовки в верхней части таблицы */
    z-index: 1; /* Устанавливаем z-index для правильного отображения */
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

.buttonDelete {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.buttonDelete:hover {
    background-color: #ff1a1a;
}

.loading {
    text-align: center;
    font-size: 1.2em;
    color: #333;
}

.error {
    text-align: center;
    font-size: 1.2em;
    color: red;
}