.reviews {
  padding: 40px 20px;
  &__input,
  &__textarea { 
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }

  &__textarea {
    min-height: 100px;
    resize: none;
  }

  &__button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }

  &__container {
    max-width: 700px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__item {
      padding: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      background-color: #f9f9f9;

      &__name {
        font-size: 18px;
        font-weight: 700;
        color: #333333;
        margin-bottom: 10px;
      }

      &__comment {
        font-size: 16px;
        color: #555555;
        line-height: 1.5;
      }
    }
  }
}


.reviews-cards {
  margin-top: 50px;
  margin-bottom: 80px;
  width: 80%;
  .review-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .review-card h3 {
      margin-top: 0;
      font-size: 1.2em;
      color: #333;
  }

  .review-content p {
      margin: 10px 0;
      color: #555;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
  }

  .review-content p.expanded {
      max-height: none;
  }

  .review-content p:not(.expanded) {
      max-height: 4.5em; /* 3 строки по 1.5em каждая */
  }

  .read-more-button {
      background-color: #00B246;
      color: white;
      padding: 3px 8px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
  }

  .read-more-button:hover {
      background-color: #008535;
  }

  .review-card small {
      color: #888;
      font-size: 0.9em;
  }

  .hotel-response {
      margin-top: 15px;
      padding: 10px;
      background-color: #f9f9f9;
      border-radius: 4px;
  }

  .hotel-response strong {
      color: #007bff;
  }
}


@media only screen and (max-width: 992px) {
  .reviews-cards {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 98%;
  }
}