.payment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f7fc;  // Цвет фона
  }
  
  .payment-card {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    text-align: center;
  }
  
  .payment-card h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .booking-info {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .booking-info p {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .booking-info strong {
    font-weight: bold;
  }
  
  .payment-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .payment-button:hover {
    background-color: #45a049;
  }
  