.slider {
  position: relative;
  width: 100%;
  height: 90vh; /* Высота на больших экранах */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top; /* Центрируем фон */
  opacity: 0;
  transition: opacity 1s ease-in-out;
  object-fit: cover;
}

.slide.active {
  opacity: 1;
}

.slide h1 {
  font-family: 'bar';
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5rem;
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

/* Медиа-запросы для маленьких экранов */
@media (max-width: 768px) {
  .slider {
    height: 50vh; /* Уменьшенная высота для планшетов */
  }

  .slide h1 {
    font-size: 1.5rem; /* Меньший текст на маленьких экранах */
  }
}

@media (max-width: 480px) {
  .slider {
    height: 25vh; /* Высота для мобильных устройств */
  }

  .slide h1 {
    font-size: 1.2rem; /* Еще меньший текст */
  }
}