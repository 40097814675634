.fw-b {
  font-weight: 800;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'bar' !important;
}


body {
  color: #0d3036;
}
@font-face {
  font-family: 'bar';
  src: url('../../../public/static/fonts/bar.ttf');
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}
@font-face {
  font-family: 'bai';
  src: url('../../../public/static/fonts/bai.ttf');
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}
@font-face {
  font-family: 'bab';
  src: url('../../../public/static/fonts/bab.ttf');
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}
@font-face {
  font-family: 'babi';
  src: url('../../../public/static/fonts/babi.ttf');
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}

pre {
  text-wrap: wrap;
  text-align: justify;
}
a {
  cursor: pointer;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  text-decoration: none;
  color: #3d3d3d;
}
.red-text {
  color: #ca1d09 !important;
}
.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}
.pos-fixed {
  position: fixed;
}
.pos-rel {
  position: relative;
}
.closer-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.remove-scrolling {
  height: 100%;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}
.hover-fun:hover {
  color: #0056b3;
}

 // Default
$main-color: #00B246;
$main-color-hover: #005321;
$text-color: #424242;
$text-btn-color: #fff;

.hover-main-color:hover {
  color: $main-color !important;
}
.head-title {
  margin-top: 24px;
  text-align: center;
  font-size: 32px;
  color: #00B246;
}
.slider-reviews {
  .swiper-wrapper {
    padding: 12px 0;
  }
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-reviews-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: center;

    .room-card__text {
      font-size: 1rem;
      color: #555;
      margin-bottom: 15px;
    }

    .room-name.room-card__client-name {
      font-size: 1.2rem;
      color: #333;
      font-weight: bold;
    }
  }

  .swiper-pagination {
    bottom: 20px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #ccc;
      opacity: 1;
      transition: background-color 0.3s ease;

      &.swiper-pagination-bullet-active {
        background-color: #333;
      }
    }
  }
}
// RoomSlider.scss
.room-slider {
  margin-top: 60px;
  margin-bottom: 60px;
  h1 {
    text-align: center;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 50px;
  }
  .message-error {
    text-align: center;
    color: red;
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .room-card {
    // background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s ease;
    &__text {
      text-align: justify;
      line-height: 30px;
      color: #535353;
    }
    &:hover {
      transform: translateY(-5px);
    }
    &__client-name {
      text-align: center;
    }

    .room-image {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .room-name {
      font-size: 1.2rem;
      margin: 15px 0 10px;
      color: $main-color;
    }

    .room-details-link {
      display: inline-block;
      margin-bottom: 15px;
      color: #929292;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.rooms-page-block {
  margin-top: 56px;
}
.rooms-page__price-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.rooms-page__price-old {
  text-decoration: line-through;
  color: #b3b3b3;
  font-size: 0.8rem;
}

.rooms-page__price-discounted {
  font-size: 1rem;
  font-weight: bold;
  color: #28a745;
}

.rooms-page__discount-badge {
  background-color: #ff6347;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 5px;
}

.rooms-title {
  text-align: center;
  color: $main-color;
  font-size: 24px;
}
// Rooms page
.rooms-page-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 4px; /* Space between the cards */
  padding: 20px;
}
.rooms-page-list-message {
  margin-top: 30px;
  text-align: center;
}

.rooms-page-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.rooms-page__image {
  width: 100%;
  height: auto;
  border-radius: 0;
}

.rooms-page__details {
  margin-top: 10px;
}
.rooms-page__name {
  color: $main-color;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 12px;
}
.rooms-page__item {
  font-size: 16px;
  margin-bottom: 6px;
  color:#2b2c2e;
}

.rooms-page__prices {
  margin-top: 20px;
  margin-bottom: 20px;
}

.rooms-page__price-per-night {
  font-size: 1rem;
  color: green;
}

.rooms-page__total-price {
  font-size: 1rem;
  color: red;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .rooms-page-list {
      grid-template-columns: 1fr 1fr; /* 2 items per row */
  }
}

@media (max-width: 480px) {
  .rooms-page-list {
      grid-template-columns: 1fr; /* 1 item per row */
  }
}


// Header 
$header-bg-color: #fff;
$headertext-color: #000;
$highlight-color: #f77f00;
$btn-hover-color: darken($highlight-color, 10%);
$header-height: 100px;
$burger-line-width: 35px;
$burger-line-height: 4px;

.wrapper {
  width: 95%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  &__flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.login-btn-auth {
  display: none;
}
.lang-switcher {
  background-color: $main-color; // Основной цвет фона
  color: #fff; // Цвет текста
  border: none; // Без границ
  border-radius: 25px; // Закругленные края
  padding: 8px 16px; // Внутренние отступы
  font-size: 14px; // Размер текста
  font-weight: bold; // Жирный текст
  cursor: pointer; // Указатель при наведении
  transition: background-color 0.3s, transform 0.2s; // Плавные переходы
  margin-right: 18px;
  &:hover {
    background-color: $main-color-hover; // Более тёмный цвет при наведении
    transform: scale(1.05); // Лёгкое увеличение
  }

  &:active {
    background-color: $main-color-hover; // Ещё темнее при клике
    transform: scale(0.95); // Лёгкое уменьшение при клике
  }

  &:focus {
    outline: 2px solid #80d0ff; // Синяя рамка при фокусе
  }
}
.new-header {
  z-index: 10000;
  background-color: $header-bg-color;
  position: sticky;
  top: 0;

  &__logo {
    margin-right: 25px;
  }
  &__logo img {
    width: 130px;
  }

  &__nav {
    ul {
      display: flex;
      list-style: none;

      li {
        margin-right: 25px;

        a, button {
          background-color:unset;
          border: 0;
          cursor: pointer;
          text-decoration: none;
          color: $headertext-color;
          font-size: 18px;
          font-weight: 500;
          position: relative;
          padding-bottom: 5px;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0%;
            height: 2px;
            background-color: $main-color;
            transition: width 0.3s ease;
          }

          &:hover::after {
            width: 100%;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    .phone-link {
      margin-left: 25px;
      color: $headertext-color;
      font-size: 18px;
      font-weight: 400;
      text-decoration: none;
      transition: color 0.3s ease;
    }
  }
  .menuButton {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13%;
    padding-left: 4px;
    padding-right: 4px;
    color: $main-color;
    width: 3em;
    height: 3.5em;
    border-radius: 0.5em;
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    transition: all .3s;
    box-shadow: 6px 6px 12px #c5c5c5,
              -6px -6px 12px #ffffff;

    &:active {
      color: #666;
      box-shadow: inset 4px 4px 12px #c5c5c5,
                inset -4px -4px 12px #ffffff;
    }
    span {
      width: 30px;
      height: 4px;
      background: #131313;
      border-radius: 100px;
      transition: 0.3s ease;
    }
    &.active span {
      background: $main-color;
    }
    &.active span:nth-child(1) {
      transform: translateY(320%)rotate(45deg);
      width: 40px;
    }
    &.active span:nth-child(2) {
      transform: translateX(-20px);
      opacity: 0;
    }
    &.active span:nth-child(3) {
      transform: translateY(-220%) rotate(-45deg);
      width: 40px;
      box-shadow: 0 0 6px $main-color;
    }
  }

  &__mobile-menu {
    display: none;
    background-color: $header-bg-color;
    position: absolute;
    top: $header-height;
    right: 0;
    width: 100%;
    padding: 25px;

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      align-items: center;
      li {
        margin-bottom: 15px;
        .phone-link {
          margin-left: 0;
        }
        button.hover-main-color {
          border: 0;
          background-color: unset;
          color: #3d3d3d;
          font-size: 20px;
        }
        a {
          font-size: 20px;
        }
      }
    }
  }
}
.pulse-button:hover {
  transform: scale(1.1); /* Увеличение при наведении */
  background-color: #10af18;
}
.pulse-button:active {
  transform: scale(0.95); /* Небольшое уменьшение при нажатии */
}
@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
      box-shadow: 0 0 0 15px rgba(0, 123, 255, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}
.pulse-button {
  animation: pulse 1.4s infinite; /* Бесконечная пульсация */
}

.button-zj {
  display: inline-block;
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: $main-color;
  transition: all 0.2s ease;
}


.button-zj:active {
  transform: scale(0.96);
}

.button-zj:before,
.button-zj:after {
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1000;
  background-repeat: no-repeat;
}

.button-zj:hover:before {
  top: -70%;
  background-image: radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, $main-color 20%, transparent 30%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, $main-color 15%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: greentopBubbles 0.6s ease;
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.button-zj:hover::after {
  bottom: -70%;
  background-image: radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, $main-color 15%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%),
    radial-gradient(circle, $main-color 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: greenbottomBubbles 0.6s ease;
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}


.abutton {
  color: #000;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}
.abutton-return-book {
  margin-top: 14px;
  padding: 0.5em 1em;
  color: $main-color;
}

.abutton:active {
  color: $main-color;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}
// Responsive styles
@media (max-width: 768px) {
  .new-header__nav,
  .new-header__actions {
    display: none;
  }

  .new-header__burger {
    display: block;
  }
  .menuButton {
    display: flex !important;
  }

  .new-header__mobile-menu.active {
    display: block;
  }
}
// Header ---!

.services {
  padding: 40px 20px;

  .services-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }

  .service-card {
    padding-bottom: 30px;

    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 500px;
    text-align: center;
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-10px);
    }

    .service-image {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    .service-title {
      margin: 15px 0 10px;
      font-size: 22px;
      color: #333;
    }

    .service-description {
      padding: 0 15px;
      font-size: 16px;
      color: #666;
    }

    .service-button {
      display: inline-block;
      margin: 20px 0;
      padding: 10px 20px;
      background-color: $main-color;
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: $main-color-hover;
      }
    }
  }
}

.mainpicservices .main_title {
  color: #fff;
}
.mainpic-block,
.mainpicservices {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
}


.mainpicservices {
  background-position: center;
  background-image: url(../../img/main/pool.jpg),
    linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
}
.mt-5 {
  margin-top: 50px;
}
.main_titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.slide-arrows {
  display: flex;
  position: absolute;
  margin-top: 100px;
  left: 50px;
  right: 50px;
  justify-content: space-between;
}
.slide-arrow {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #fff;
  padding: 10px 14px;
  transition: 0.2s;
}
.prev-slide {
}
.slide-arrow:hover {
  color: #333333;
  background-color: #fff;
}
.main_mini-title {
  font-size: 20px;
}
.main_title {
  font-family: 'bab';
  /* margin-top: 30px; */
  margin-bottom: 80px;
  font-size: 58px;
}
.main_priority {
  width: 40px;
  margin-right: 6px;
}

.container {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}
.main-block_img {
  width: 80px;
}

// About
.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  border-bottom: 1px solid #f2f5fa;
  &__gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
    padding: 16px;

    img {
      width: 100%;
      height: auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}
.about-container {
  max-width: 800px;
  text-align: justify;
  background: rgba(255, 255, 255, 0.063); /* Semi-transparent overlay */
  padding: 2rem;
  border-radius: 10px;
  // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 8px rgba(255, 255, 255, 0.2);
  color: $main-color;
}

.about-text {
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.6;
  color: $text-color;
  text-align: justify;
    
}

@media (max-width: 768px) {
  .about-title {
    font-size: 2rem;
  }

  .about-text {
    font-size: 1rem;
  }
}
// About ---!

// Services

.services-section {
  padding: 3rem 1rem;
  text-align: center;
  overflow-x: hidden;
}

.services-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: $main-color;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.services-grid {
  display: flex;
  gap: 1.5rem;
  padding-bottom: 1rem;
  scroll-snap-type: x mandatory;
  animation: scroll 20s linear infinite;
  &:hover {
    animation-play-state: paused; /* Остановка при наведении */
  }
}
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}
.services-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex: 0 0 400px; /* фиксированная ширина карточки */
  scroll-snap-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
}

.services-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-bottom: 2px solid #e6e6e6;
}

.services-card-title {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: $main-color;
}

.services-description {
  padding: 0 1.5rem 1.5rem;
  font-size: 1rem;
  color: $text-color;
  line-height: 1.6;
}
/* Скрываем полосу прокрутки на WebKit-браузерах */
.services-grid::-webkit-scrollbar {
  display: none;
}
// Services ---!


.main-rooms {
  margin-top: 100px;
}
.main-rooms-blocks {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.main-rooms-block {
  margin-top: 70px;
}
.main-rooms-block_img {
  width: 550px;
}
.main-rooms-block_text {
  margin-top: 47px;
  font-size: 26px;
}
.main-services-blocks {
  margin-top: 100px;
  margin-bottom: 100px;
}
.main-services-block {
  margin-top: 100px;
  padding: 40px 57px;
  background-color: #f5f3f0;
  position: relative;
}
.services-block-title {
  color: $main-color;
  font-size: 48px;
  margin-bottom: 62px;
  text-align: center;
}

.underline-title::after {
  position: relative;
  content: '';
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 2px;
  margin-top: 5px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0),
    $main-color 50% 50%,
    rgba(255, 255, 255, 0)
  );
}
.underline-title-white::after {
  position: relative;
  content: '';
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 2px;
  margin-top: 5px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0),
    #ffffff 50% 50%,
    rgba(255, 255, 255, 0)
  );
}
.main-services-block_img {
  width: 50%;
}
.main-services-block_it {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-services-block_it-block-right {
  margin-left: 44px;
  width: 50%;
}
.main-services-block_it-block-left {
  margin-right: 44px;
  width: 50%;
}
.main-services-block_it-text {
  font-size: 20px;
  line-height: 43px;
  text-align: justify;
  color: $text-color;
}
.button-large {
  padding: 27px 90px;
  border: 1px solid #3d3d3d;
  margin-top: 70px;
  cursor: pointer;
  font-size: 22px;
}
.button-right {
  float: right;
}
.button-left {
  float: left;
}
.footer-contacts-us {
  margin-bottom: 140px;
}
.footer-contacts-us_hr {
  background-color: #fff;
  height: 1px;
  width: 100%;
}
.footer-contacts-us_title {
  font-family: 'bai';
  font-size: 64px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 70px;
  color: $main-color;
}
.footer-contacts-us_text {
  font-size: 22px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  line-height: 45px;
  color: $text-color;
}
footer {
  background-color: #e4e4e4;
  color: $main-color;
  position: relative;
}
footer a {
  color: $main-color;
}
footer a:hover {
  color: $main-color-hover;
}
.footer-flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer-logo {
  img {
    width: 170px;

  }
}
.copyright-text {
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
}
.footer-info-block {
  width: 30%;
  display: flex;
  align-items: center;
}
.footer-info-block_ul {
  list-style-type: none;
}
.footer-info-block_ul_li {
  display: flex;
  margin-bottom: 15px;
}
.footer-info-block_ul_li-block {
  display: flex;
  flex-direction: column;

}


.rooms {
  background-image: url(../../img/main/rooms-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.rooms_blocks {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 200px;
}
.rooms_block_imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.rooms_block_rm_img {
  height: 188px;
  padding: 20px;
}

.container-serv {
  max-width: 1455px;
  margin-right: auto;
  margin-left: auto;
}
.serv-block {
  margin-top: 40px;
  margin-bottom: 100px;
}
.serv-block_main_pic {
  width: 100%;
}
.serv-block_main {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 100px;
}
.vertical-line-left {
  padding-right: 40px;
  border-right: 2px;
  border-right-style: solid;
  border-image: linear-gradient(to top, #986f38, rgba(0, 0, 0, 0)) 1 100%;
}
.serv-block_main_text {
  font-size: 28px;
  line-height: 53px;
  text-align: justify;
}
.serv-block_imgs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.block_imgs_img {
  display: inline-block;
  margin-bottom: 60px;
  width: 700px;
}

/* Контейнер формы */
.container-rooms-choosen {
  margin: 2rem auto;
  padding: 2.5rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  max-width: 600px;
}

.container-rooms-choosen__title {
  text-align: center;
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif;
  color: $main-color;
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.guest-info {
  .title-guest-info {
    margin-bottom: 4px;
  }
  .form-group {
    margin-bottom: 6px !important;
  }
}
.form-group {
  display: flex;
  flex-direction: column;
}
.booking-form__list-data {
  list-style-type: disc;
}
.booking-form__list-data__item:not(:last-child) {
  margin-bottom: 6px;
}
.form-group__dates {
  display: flex;
}
.form-group__dates .form-group {
  width: 100%;
}

.form-group label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #34495e;
  font-family: 'Roboto', sans-serif;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #34495e;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #3498db;
  outline: none;
}

.form-group select option {
  padding: 0.5rem;
  font-size: 1rem;
}

.button-form-group {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 0.75rem;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 1.5rem;
}

.button-form-group:hover {
  background-color: #2980b9;
}

/* Стили для секции результатов */
.container-rooms-result {
  border-radius: 12px;
}

.container-rooms-result__title {
  margin-bottom: 2.5rem;
  text-align: center;
  font-size: 2rem;
  color: $main-color;
  font-family: 'Roboto', sans-serif;
}

.room-result {
  margin-bottom: 2rem;
  padding: 1.5rem;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &__hr-bottom {
    padding-bottom: 24px;
    border-bottom: 1px solid #34495e;
    margin-bottom: 24px;
  }
  &__services-room {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        color: $main-color;
        margin-bottom: 16px;
      }
    }
  }
  &__description-room {
    display: flex;
    justify-content: space-between;
    &__block {
      width: 70%;
      padding-right: 12px;
    }
    &__title {
      color: $main-color;
      margin-bottom: 12px;
      font-size: 20px;
    }
    margin-bottom: 24px;
    &__text {
      font-size: 16px;
      line-height: 34px;
      text-align: justify;
    }
  }
  &__additional-services {
    width: 30%;
    border-left: 1px solid #34495e;
    padding-left: 12px;
    list-style-type: none;
    &__title {
      font-weight: bold;
      color: $main-color;
      font-size: 18px;
    }
    &__item:not(:last-child) {
      margin-bottom: 20px;
    }
  }

}

.room-result:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.room-result__type {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: $main-color;
  text-align: center;
  font-weight: bold;
}

.room-result__main-images-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  &__main-image {
    width: 78%;
    img {
      width: 100%;
    }
  }
  &__images {
    margin-left: 12px;
    width: 20%;
    img {
      cursor: pointer;
      width: 100%;
      transition: transform 0.3s ease;
    }
    img:hover {
      transform: scale(1.01);
    }
  }
}



.room-result__price,
.room-result__guests,
.room-result__amenities,
.room-result__floor,
.room-result__description {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  color: #2c3e50;
  font-family: 'Roboto', sans-serif;
}

.room-result__payment-notice {
  color: #e74c3c;
  font-weight: bold;
}

.room-result__description {
  color: #7f8c8d;
  font-style: italic;
}
/* Стиль для кнопки бронирования */
.button-booking {
  display: block;
  background-color: $main-color;
  color: #fff;
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin: 1rem auto 0 auto;
}
.button-booking-small {
  padding: .4rem 1rem;
}
.to-book-room {
  font-size: 18px;
  padding: 1rem 2.2rem;
}
.button-booking:hover {
  background-color: $main-color-hover;
}
.button-booking:active {
  transition: 0.2s ease-out;
  transform: scale(0.99);
}

.background-floor {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #383838b2;
  z-index: 10000;
  display: flex !important;
  justify-content: center;
}
.searching-status-booking {
  text-align: center;
}
.form-simple {
  min-width: 400px;
  max-height: 100%;
  max-width: 500px;
  padding: 40px;
  border-radius: 12px;
  background-color: #fff;
  margin: auto;
  &__title {
    text-align: right;
    font-size: 24px;
    color: $main-color;
    margin-bottom: 12px;
  }
  .form-group {
    margin-bottom: 22px;
  }
  .toggleClientForm {
    margin-top: 16px;
    span {
      color: #3498db;
      text-decoration: underline;
      font-size: 18px;
      cursor: pointer;
    }
    span:hover {
      color: #2980b9;
    }
  }

}

.booking-form-pos {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.booking-form {
  overflow-y: scroll;
  max-height: 100%;
  padding: 40px;
  border-radius: 12px;
  margin: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__guests {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__link-cancel {
    color: #d9534f;           // Красный цвет, ассоциирующийся с предупреждением
    text-decoration: underline;
    font-weight: 600;         // Жирный текст для привлечения внимания
    
    &:hover {
      color: #c9302c;         // Более тёмный оттенок при наведении
      text-decoration: none;  // Убираем подчёркивание при наведении
    }
  }
}
.disabled-date {
  color: gray;
  pointer-events: none;
}
.booking-form .form-group {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.booking-form .form-group label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.booking-form .form-group input,
.booking-form .form-group select,
.booking-form .form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.booking-form .form-group textarea {
  resize: vertical;
  min-height: 100px;
}
.gallery {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .gallery-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: $main-color; // Цвет текста
    text-align: center; // По центру
  }

  .row {
    width: 100%;
    margin: 0 auto;

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // Адаптивная сетка
      gap: 20px; // Отступы между изображениями
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Легкая тень

        img {
          width: 100%;
          height: 100%;
          object-fit: cover; // Обрезка изображения по размеру элемента
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05); // Небольшое увеличение при наведении
          }
        }
      }
    }
  }
  /* Стили для модального окна */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  
  img {
    width: 100%;
    height: auto;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
  }
}
}

// styles/BooksComponent.scss
.books-container {
  padding: 2rem;
  background-color: #f8f9fa;

  .books-title {
    text-align: center;
    font-size: 2rem;
    color: $main-color;
    margin-bottom: 1.5rem;
  }

  .books-list {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // gap: 1.5rem;

    .booking-item:not(:first-child) {
      margin-left: 32px;
    }
    .booking-item {
      max-width: 400px;
      background-color: #fff;
      padding: 1.5rem;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-left: 5px solid $main-color;

      a {
        display: inline-block;
      }

      h3 {
        margin-bottom: 0.5rem;
        color: $main-color;
      }

      p {
        margin: 0.3rem 0;
        color: #555;
      }

      &:hover {
        transform: translateY(-5px);
        transition: transform 0.3s ease;
      }
    }
  }
}
.booking-item-guest {
  border-bottom: 1px solid #e2e2e2;
}
.slider__main-text {
  color: $main-color !important;
}
.return-payment-form {
  position: absolute;
  width: 100%;
  height: 100%; // Для обеспечения центрации по вертикали
  display: flex;
  justify-content: center; // Центрирование по горизонтали
  align-items: center; // Центрирование по вертикали
}
.document-upload-form {
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    padding: 10px 0;
    margin-bottom: 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        color: #555;
      }

      input[type="file"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;

        &:focus {
          outline: none;
          border-color: #007bff;
        }
      }
    }

    button[type="submit"] {
      padding: 10px 0;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #218838;
      }
    }
  }
}

/* Общие стили */
.ourservices {
  padding: 20px;

  .services-title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
  }

  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0;
    list-style: none;
  }

  .service-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.5s ease;
    opacity: 0; /* Начальная прозрачность */
    transform: translateY(20px); /* Начальное смещение снизу */

    &.visible {
      opacity: 1; /* При появлении становится видимым */
      transform: translateY(0); /* Возвращается в исходное положение */
    }

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    }

    .service-title {
      font-size: 24px;
      color: #333;
      margin-bottom: 15px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        width: 100%;
        height: 1px;
        background-color: #28a745; /* Зеленая линия */
      }
    }

    .service-description {
      font-size: 16px;
      color: #555;
    }
  }
}

/* Мобильная версия */
@media (max-width: 768px) {
  .ourservices {
    .services-title {
      font-size: 28px;
    }

    .service-title {
      font-size: 20px;
    }

    .service-description {
      font-size: 14px;
    }
  }
}

/* Очень маленькие экраны (например, смартфоны в портретной ориентации) */
@media (max-width: 480px) {
  .ourservices {
    .services-title {
      font-size: 24px;
    }

    .service-item {
      padding: 15px;
    }

    .service-title {
      font-size: 18px;
    }

    .service-description {
      font-size: 12px;
    }
  }
}


.block-form-login-manual {
  display: flex;
  justify-content: center;
  align-items: center;

  &__block {
    margin-left: 32px;
    width: 500px;
    video {
      width: 90%;
    }

  }
}

.service-page {
  max-width: 1100px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  .title {
    color: #00B246;
    font-size: 32px;
    font-weight: bold;
  }
  .undertitle {
    font-size: 18px;
    color: #242424;
  }
  .under-line {
    text-decoration: underline;
  }
  & > *:not(:last-child) {
    margin-bottom: 10px; // Отступ между внутренними элементами
  }

  .service-main-image {
    img {
      max-height: 550px !important;
    }
  }
  .service-image {
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    img {
      width: 100%;
      height: 100%;
      max-height: 400px;
      display: block;
      object-fit: cover;
    }
  }
  .service-description {
    white-space: pre-wrap;
    font-size: 16px;
  }

  .service-gallery {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: minmax(200px, auto); // Минимальная высота 200px, но может расширяться
    gap: 15px;
    width: 100%;
    max-width: 1200px;
    grid-auto-flow: dense; // Заполняет сетку более эффективно

  }
}