/* App.css */
.system-background {
  background-color: transparent;
  background-image: radial-gradient(#000000 1px, #e5e5f7 1px);
  background-size: 30px 30px;
  width: 100%;
  height: 100%;
}

.d-none {
  display: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

.system-sidebar {
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 40px;
  padding-bottom: 32px;
  height: 100vh;
  max-width: 350px;
  background-color: #2c3e50;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    font-size: 1.8rem;
    margin: 20px 0;
  }
  nav {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 50px;
    }
  }
  nav ul {
    list-style-type: none;
    width: 100%;
  }
  
  nav ul li {
    width: 100%;
  }
  
  nav ul li a {
    text-decoration: none;
    color: white;
    display: block;
    padding: 15px 20px;
    transition: background 0.3s;
  }
  
  nav ul li a:hover {
    background-color: #34495e;
  }
}



.system-main-content {
  overflow-x: hidden;
  max-height: 97vh;
  flex-grow: 1;
  padding: 20px;
}

.system-header {
  z-index: -2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.system-header__view-image {
  margin-right: 6px;
  display: inline-block;
}
.system-header__edit-image {
  margin-right: 6px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  transition: filter 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.system-header__edit-image:hover {
  filter: brightness(70%);
  transform: scale(1.04);
}
.system-header__edit-image:active {
  transform: scale(.99);
}
.system-header__edit-image::after {
  content: "Удалить фото";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6); // Черный фон с прозрачностью
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.system-header__edit-image:hover::after {
  opacity: 1;
}

.system-header h1 {
  font-size: 2rem;
  color: #34495e;
}

.system-header .user-info {
  font-size: 1rem;
}

.system-header .user-info a {
  text-decoration: none;
  color: #e74c3c;
}
.system-body {
  display: flex;
  
}
.system-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
  .card {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .card h2 {
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .card p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #16a085;
  }
}


@media (max-width: 768px) {
  .system-sidebar {
    width: 200px;
  }

  .system-main-content {
    padding: 10px;
  }

  .system-header h1 {
    font-size: 1.5rem;
  }

  .system-dashboard {
    grid-template-columns: 1fr;
  }
}

// Table of booking

.system-bookings-table {
  display: block;
  overflow-y: auto;
  width: 100%;
  max-height: 76vh;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  &__title {
    margin-bottom: 12px;
  }
}

.system-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.system-thead {
  position: sticky;
  top: 0;
}
.system-thead th {
  text-align: left;
  padding: 12px;
  background-color: #2c3e50;
  color: white;
  font-weight: bold;
}
.system-th {
  &__cursor {
    background-color: #3e6891 !important;
    cursor: pointer;
  }
}

.system-tbody tr {
  border-bottom: 1px solid #e1e1e1;
}
.system-tr-removed {
  background-color: #db3434;
}

.system-tbody tr:last-child {
  border-bottom: none;
}

.system-tbody td {
  padding: 12px;
  font-size: 0.9rem;
  button {
    margin-top: 12px;
  }
}

.system-tbody tr:nth-child(even) {
  background-color: #ffefc5;
}

.system-status {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
}

.system-status.confirmed {
  background-color: #16a085;
  color: white;
}

.system-status.pending {
  background-color: #f39c12;
  color: white;
}

.flex-btns {
  margin-top: 12px;
  display: flex;
  justify-content: end;
}
.system-booking-table-btn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-radius: 5px;
  transition: all ease 0.1s;
  box-shadow: 0px 4px 0px 0px #a29bfe;
  margin-right: 14px;
  &:disabled {
    background-color: #ccc;  // Серый фон для выключенной кнопки
    color: #666;            // Серый текст
    cursor: not-allowed;    // Курсор "недоступно"
    opacity: 0.7;           // Полупрозрачность для отключенного состояния
    pointer-events: none;   // Отключает любые события на кнопке
  }
}
.system-booking-table-btn:last-child {
  margin-right: 0;
}
.system-booking-table-btn:active {
  transform: translateY(4px);
  box-shadow: 0px 0px 0px 0px #a29bfe;
}
.system-booking-table-btn-edit {
  background-color: #db7f34;
}
.system-booking-table-btn-view {
  background-color: #3498db;
}
.system-booking-table-btn-cancel {
  background-color: #db3434;
}

@media (max-width: 768px) {
  .system-table,
  .system-thead,
  .system-tbody,
  .system-th,
  .system-td,
  .system-tr {
    display: block;
  }

  .system-thead {
    display: none;
  }

  .system-tbody tr {
    margin-bottom: 15px;
    border-bottom: none;
  }

  .system-tbody td {
    display: flex;
    justify-content: space-between;
    padding-left: 50%;
    position: relative;
    text-align: right;
  }

  .system-tbody td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    text-align: left;
    font-weight: bold;
  }
}

// Booking card
.system-booking-details {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.system-card-booking {
  width: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.system-card-header {
  background-color: #34495e;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.system-card-header h2 {
  margin: 0;
}

.system-close-card-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.system-card-body {
  padding: 20px;
}

.system-card-info-group {
  margin-bottom: 15px;
}

.system-card-info-group h3 {
  margin-bottom: 5px;
  color: #34495e;
}

.system-card-info-group p {
  font-size: 1rem;
}

.system-card-status {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
}

.system-card-status.confirmed {
  background-color: #16a085;
  color: white;
}

.system-card-footer {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background-color: #ecf0f1;
}

.system-card-btn {
  text-decoration: none;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

.system-card-btn:hover {
  background-color: #2980b9;
}

.system-card-btn.cancel {
  background-color: #e74c3c;
}

.system-card-btn.cancel:hover {
  background-color: #c0392b;
}

@media (max-width: 480px) {
  .system-card {
    width: 90%;
  }
}

.system-input {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.system-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.system-input:hover {
  border-color: #66afe9;
}


// Edit form System
.system-table-room-availability {
  &__title {
    margin-bottom: 12px;
  }
}
.system-background-hide {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(56, 56, 56, 0.6980392157);
  display: flex;
  z-index: 1;
}
.system-container {
  margin: auto;
  position: relative;
  max-width: 1200px;
  width: 100%;
  background: #ffffff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  &__inner {
    width: 100%;
    &-images {
      margin-left: 12px;
      img {
        width: 150px;
      }
    }
  }
}

.system-header {
  font-size: 1.2rem;
  color: #000;
  font-weight: 600;
  text-align: center;
}

.system-form {
  margin-top: 15px;
}
.system-container-form {
  max-height: 100%;
  overflow: scroll;
}
.system-form .system-input-box {
  width: 100%;
  margin-top: 10px;
}

.system-input-box label {
  display: block;
  color: #000;
  margin-bottom: 4px
}
.amenity-item {
  button {
    margin-left: 4px;
  }
  margin-bottom: 4px;
  display: flex;
  margin-bottom: 12px;
}
.system-form :where(.system-input-box textarea) {
  min-height: 50px;
}
.system-input-box input[type=file] {
  border: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
}
.system-form :where(.system-input-box input, .system-input-box textarea, .system-select-box) {
  position: relative;
  height: 35px;
  width: 100%;
  outline: none;
  font-size: 1rem;
  color: #808080;
  margin-top: 5px;
  border: 1px solid #a29bfe;
  border-radius: 6px;
  padding: 0 15px;
  background: #FCEDDA;
}

.system-input-box input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.system-form .system-column {
  display: flex;
  column-gap: 15px;
}

.system-form .system-gender-box {
  margin-top: 10px;
}

.system-form :where(.system-gender-option, .system-gender) {
  display: flex;
  align-items: center;
  column-gap: 50px;
  flex-wrap: wrap;
}

.system-gender {
  column-gap: 5px;
}

.system-gender input {
  accent-color: #a29bfe;
}

.system-form :where(.system-gender input, .system-gender label) {
  cursor: pointer;
}

.system-gender label {
  color: #000;
}

.system-address :where(input, .system-select-box) {
  margin-top: 10px;
}

.system-select-box select {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  color: #808080;
  font-size: 1rem;
  background: #FCEDDA;
}

.error-message {
  color: #c0392b;
  display: block;
}
.success-message {
  margin: 12px;
  color: #2bc044;
}

.search-input {
  margin-bottom: 10px;
  padding: 12px;
  width: 100%;
  max-width: 300px;
  border-radius: 4px;
  border: 1px solid #bebebe;
}

.search-field, .search-field::placeholder{
  color: #fff;
  background-color: #a0615a !important;
}

// Loader
.loader {
  --dim: 1.5rem;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
}

.loader .circle {
  --color: #333;
  --dim: .5rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%, 25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%, 50% {
    transform: scale(1) rotate(180deg);
  }

  70%, 75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%, 100% {
    transform: scale(1) rotate(360deg);
  }
}

.d-flex {
  display: flex;
}
.d-flex.just-content-sb {
  justify-content: space-between
}




// 404
//variables
$purple: #28254C;
$l-purple: #332F63;
$t-purple: #8C8AA7;
$pink: #FF5E65;
$white: #fff;
.page-not-found-body {
  width: 100%;
  height: 100vh;
  background-color: $purple !important;
}
.page-not-found-box {
  width: 350px;
  height: 100%;
  max-height: 600px;
  min-height: 450px;
  background: $l-purple;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  
  .page-not-found-box__ghost {
    padding: 15px 25px 25px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);

    .symbol{
      &:nth-child(1) {
        opacity: .2;
        animation: shine 4s ease-in-out 3s infinite;
        
        &:before, &:after {
          content: '';
          width: 12px;
          height: 4px;
          background: $white;
          position: absolute;
          border-radius: 5px;
          bottom: 65px;
          left: 0;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
      &:nth-child(2) {
        position: absolute;
        left: -5px;
        top: 30px;
        height: 18px;
        width: 18px;
        border: 4px solid;
        border-radius: 50%;
        border-color: $white;
        opacity: .2;
        animation: shine 4s ease-in-out 1.3s infinite;
      }
      &:nth-child(3) {
        opacity: .2;
        animation: shine 3s ease-in-out .5s infinite;
        
        &:before, &:after {
          content: '';
          width: 12px;
          height: 4px;
          background: $white;
          position: absolute;
          border-radius: 5px;
          top: 5px;
          left: 40px;
        }
        &:before {
          transform: rotate(90deg);
        }
        &:after {
          transform: rotate(180deg);
        }
      }
      &:nth-child(4) {
        opacity: .2;
        animation: shine 6s ease-in-out 1.6s infinite;
        
        &:before, &:after {
          content: '';
          width: 15px;
          height: 4px;
          background: $white;
          position: absolute;
          border-radius: 5px;
          top: 10px;
          right: 30px;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
      &:nth-child(5) {
        position: absolute;
        right: 5px;
        top: 40px;
        height: 12px;
        width: 12px;
        border: 3px solid;
        border-radius: 50%;
        border-color: $white;
        opacity: .2;
        animation: shine 1.7s ease-in-out 7s infinite;
      }
      &:nth-child(6) {
        opacity: .2;
        animation: shine 2s ease-in-out 6s infinite;
        
        &:before, &:after {
          content: '';
          width: 15px;
          height: 4px;
          background: $white;
          position: absolute;
          border-radius: 5px;
          bottom: 65px;
          right: -5px;
        }
        &:before {
          transform: rotate(90deg);
        }
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    .page-not-found-box__ghost-container {
      background: $white;
      width: 100px;
      height: 100px;
      border-radius: 100px 100px 0 0;
      position: relative;
      margin: 0 auto;
      animation: upndown 3s ease-in-out infinite;

      .page-not-found-box__ghost-eyes {
        position: absolute;
        left: 50%;
        top: 45%;
        //transform: translate(-50%, -45%); 
        height: 12px;
        width: 70px;
        
        .page-not-found-box__eye-left {
          width: 12px;
          height: 12px;
          background: $l-purple;
          border-radius: 50%;
          margin: 0 10px;
          position: absolute;
          left: 0;
        }
        .page-not-found-box__eye-right {
          width: 12px;
          height: 12px;
          background: $l-purple;
          border-radius: 50%;   
          margin: 0 10px;
          position: absolute;
          right: 0;
        }
      }

      .page-not-found-box__ghost-bottom {
        display:flex;
        position: absolute;
        top:100%;
        left:0;
        right:0;

        div {
          flex-grow:1;
          position: relative;
          top:-10px;
          height:20px;
          border-radius:100%;
          background-color: $white;

          &:nth-child(2n) {
            top: -12px;
            margin: 0 -0px;
            border-top: 15px solid $l-purple;
            background: transparent;
          }
        }
      }
    }
      
    .page-not-found-box__ghost-shadow {
      height: 20px;
      box-shadow: 0 50px 15px 5px #3B3769;
      border-radius: 50%;
      margin: 0 auto;
      animation: smallnbig 3s ease-in-out infinite;
    }
  }
  
  .page-not-found-box__description {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    
    .page-not-found-box__description-container {
      color: $white;
      text-align: center;
      width: 200px;
      font-size: 16px;
      margin: 0 auto;
    
      .page-not-found-box__description-title {
        font-size: 24px;
        letter-spacing: .5px;
      }

      .page-not-found-box__description-text {
        color: $t-purple;
        line-height: 20px;
        margin-top: 20px;
      }
    }
    
    .page-not-found-box__button {
      display: block;
      position: relative;
      background: $pink;
      border: 1px solid transparent;
      border-radius: 50px;
      height: 50px;
      text-align: center;
      text-decoration: none;
      color: $white;
      line-height: 50px;
      font-size: 18px;
      padding: 0 70px;
      white-space: nowrap;
      margin-top: 25px;
      transition: background .5s ease;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 100px;
        background: $white;
        bottom: -25px;
        left: 0;
        border: 2px solid $white;
        transform: translateX(-50px) rotate(45deg);
        transition: transform .5s ease;
      }
      
      &:hover {
        background: transparent;
        border-color: $white;
        
        &:before {
          transform: translateX(250px) rotate(45deg);
        }
      }
    }
  }
}

//keyframes
@keyframes upndown {
  0% {transform: translateY(5px);}
  50% {transform: translateY(15px);}
  100% {transform: translateY(5px);}
}
@keyframes smallnbig {
  0% {width: 90px;}
  50% {width: 100px;}
  100% {width: 90px;}
}
@keyframes shine {
  0% {opacity: .2;}
  25% {opacity: .1;}
  50% {opacity: .2;}
  100% {opacity: .2;}
}


// Login form
.login-form-backg {
  display: flex;;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}
.login-form {
  --bg-light: #efefef;
  --bg-dark: #707070;
  --clr: #58bc82;
  --clr-alpha: #9c9c9c60;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  background-color: #212121;
  padding: 24px;
  border-radius: 20px;
}

.login-form .input-span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.login-form input {
  border-radius: 0.5rem;
  padding: 1rem 0.75rem;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--clr-alpha);
  outline: 2px solid var(--bg-dark);
}

.login-form input:focus,
.login-form input:focus {
  outline: 2px solid var(--clr);
}

.login-label {
  align-self: flex-start;
  color: var(--clr);
  font-weight: 600;
}

.login-form button {
  text-align: center;
  padding: 1rem 0.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 3rem;
  background-color: var(--bg-dark);
  color: var(--bg-light);
  border: none;
  cursor: pointer;
  transition: all 300ms;
  font-weight: 600;
  font-size: 0.9rem;
}

.login-form button:hover {
  background-color: var(--clr);
  color: var(--bg-dark);
}

.login-span {
  text-decoration: none;
  color: var(--bg-dark);
}

.login-span a {
  color: var(--clr);
}


.calendar-table {
  table {
    width: 100%;
    border-collapse: collapse;
    th, td {
      border: 1px solid #ddd;
      text-align: center;
      padding: 8px;
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
    }

    td.booked {
      background-color: #ffcccc;
    }

    td.available {
      background-color: #ccffcc;
    }
  }
}
.red-background {
  background-color: #ff19009a !important;
}

.block-message {
  margin: 15px;
  color: $pink
}

.dinput-block {
  width: 100%;
  margin-bottom: 12px;
}
.dinput[type="date"] {
  display: block;
  width: 100%; // Замените на фиксированную ширину, если нужно
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  appearance: none; // Убирает стандартный вид браузера
  outline: none;
  cursor: pointer;

  &:focus {
    border-color: #007bff; // Цвет рамки при фокусе
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(0.5); // Меняет вид иконки календаря (по желанию)
    cursor: pointer;
  }
}