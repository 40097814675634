/* Reviews.module.scss */
.Reviews {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .btnShowForm {
    margin-top: 8px;
  }
  .answerbtn {
    background-color: green;
    color: white;
  }
  .chancgeAnswerbtn {
    background-color: rgb(255, 94, 0);
    color: white;
  }
  .formAnswer {
    position: relative;
    textarea {
      width: 100%;
      height: 100px;
      font-size: 16px;
    }

  }
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 20px;

    select {
      padding: 10px;
      font-size: 1rem;
      border-radius: 4px;
      border: 1px solid #ccc;
      width: 100%;
      box-sizing: border-box;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      background-color: #fff;
      padding: 20px;
      margin-bottom: 15px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      position: relative;

      strong {
        font-size: 1.2rem;
        color: #333;
      }

      small {
        display: block;
        margin-top: 5px;
        color: #777;
      }

      .greenbtn {
        background-color: green;
        color: white;
      }
      .status {
        font-weight: bold;

        &.published {
          color: green;
        }

        &.unpublished {
          color: red;
        }
      }

      .delete {
        background-color: red;
        color: white;
        border: none;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: darkred;
        }
      }
      .btns {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .button:not(:last-child) {
        margin-right: 12px;
      }
      .button {
        padding: 5px 10px;
        font-size: 0.9rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &.publish {
          background-color: green;
          color: white;
        }

        &.unpublish {
          background-color: rgb(255, 94, 0);
          color: white;
        }
      }
    }
  }
}