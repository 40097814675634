@charset "UTF-8";
#root {
  width: 100%;
}

.fw-b {
  font-weight: 800;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "bar" !important;
}

body {
  color: #0d3036;
}

@font-face {
  font-family: "bar";
  src: url("../../../public/static/fonts/bar.ttf");
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}
@font-face {
  font-family: "bai";
  src: url("../../../public/static/fonts/bai.ttf");
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}
@font-face {
  font-family: "bab";
  src: url("../../../public/static/fonts/bab.ttf");
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}
@font-face {
  font-family: "babi";
  src: url("../../../public/static/fonts/babi.ttf");
  font-display: swap; /* Включение fallback-шрифта до загрузки основного */
}
pre {
  text-wrap: wrap;
  text-align: justify;
}

a {
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
  color: #3d3d3d;
}

.red-text {
  color: #ca1d09 !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.pos-fixed {
  position: fixed;
}

.pos-rel {
  position: relative;
}

.closer-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.remove-scrolling {
  height: 100%;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-fun:hover {
  color: #0056b3;
}

.hover-main-color:hover {
  color: #00B246 !important;
}

.head-title {
  margin-top: 24px;
  text-align: center;
  font-size: 32px;
  color: #00B246;
}

.slider-reviews {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}
.slider-reviews .swiper-wrapper {
  padding: 12px 0;
}
.slider-reviews h1 {
  text-align: center;
  margin-bottom: 30px;
}
.slider-reviews .swiper-container {
  width: 100%;
  height: 100%;
}
.slider-reviews .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-reviews .slider-reviews-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
}
.slider-reviews .slider-reviews-card .room-card__text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}
.slider-reviews .slider-reviews-card .room-name.room-card__client-name {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}
.slider-reviews .swiper-pagination {
  bottom: 20px;
}
.slider-reviews .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  opacity: 1;
  transition: background-color 0.3s ease;
}
.slider-reviews .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #333;
}

.room-slider {
  margin-top: 60px;
  margin-bottom: 60px;
}
.room-slider h1 {
  text-align: center;
  text-transform: uppercase;
  color: #00B246;
  margin-bottom: 50px;
}
.room-slider .message-error {
  text-align: center;
  color: red;
  font-size: 1.2rem;
  margin-top: 20px;
}
.room-slider .room-card {
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
  transition: transform 0.3s ease;
}
.room-slider .room-card__text {
  text-align: justify;
  line-height: 30px;
  color: #535353;
}
.room-slider .room-card:hover {
  transform: translateY(-5px);
}
.room-slider .room-card__client-name {
  text-align: center;
}
.room-slider .room-card .room-image {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
.room-slider .room-card .room-name {
  font-size: 1.2rem;
  margin: 15px 0 10px;
  color: #00B246;
}
.room-slider .room-card .room-details-link {
  display: inline-block;
  margin-bottom: 15px;
  color: #929292;
  text-decoration: none;
  font-weight: bold;
}
.room-slider .room-card .room-details-link:hover {
  text-decoration: underline;
}

.rooms-page-block {
  margin-top: 56px;
}

.rooms-page__price-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.rooms-page__price-old {
  text-decoration: line-through;
  color: #b3b3b3;
  font-size: 0.8rem;
}

.rooms-page__price-discounted {
  font-size: 1rem;
  font-weight: bold;
  color: #28a745;
}

.rooms-page__discount-badge {
  background-color: #ff6347;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 5px;
}

.rooms-title {
  text-align: center;
  color: #00B246;
  font-size: 24px;
}

.rooms-page-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 4px; /* Space between the cards */
  padding: 20px;
}

.rooms-page-list-message {
  margin-top: 30px;
  text-align: center;
}

.rooms-page-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.rooms-page__image {
  width: 100%;
  height: auto;
  border-radius: 0;
}

.rooms-page__details {
  margin-top: 10px;
}

.rooms-page__name {
  color: #00B246;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.rooms-page__item {
  font-size: 16px;
  margin-bottom: 6px;
  color: #2b2c2e;
}

.rooms-page__prices {
  margin-top: 20px;
  margin-bottom: 20px;
}

.rooms-page__price-per-night {
  font-size: 1rem;
  color: green;
}

.rooms-page__total-price {
  font-size: 1rem;
  color: red;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .rooms-page-list {
    grid-template-columns: 1fr 1fr; /* 2 items per row */
  }
}
@media (max-width: 480px) {
  .rooms-page-list {
    grid-template-columns: 1fr; /* 1 item per row */
  }
}
.wrapper {
  width: 95%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}
.wrapper__flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-btn-auth {
  display: none;
}

.lang-switcher {
  background-color: #00B246;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-right: 18px;
}
.lang-switcher:hover {
  background-color: #005321;
  transform: scale(1.05);
}
.lang-switcher:active {
  background-color: #005321;
  transform: scale(0.95);
}
.lang-switcher:focus {
  outline: 2px solid #80d0ff;
}

.new-header {
  z-index: 10000;
  background-color: #fff;
  position: sticky;
  top: 0;
}
.new-header__logo {
  margin-right: 25px;
}
.new-header__logo img {
  width: 130px;
}
.new-header__nav ul {
  display: flex;
  list-style: none;
}
.new-header__nav ul li {
  margin-right: 25px;
}
.new-header__nav ul li a, .new-header__nav ul li button {
  background-color: unset;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
}
.new-header__nav ul li a::after, .new-header__nav ul li button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background-color: #00B246;
  transition: width 0.3s ease;
}
.new-header__nav ul li a:hover::after, .new-header__nav ul li button:hover::after {
  width: 100%;
}
.new-header__actions {
  display: flex;
  align-items: center;
}
.new-header__actions .phone-link {
  margin-left: 25px;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.3s ease;
}
.new-header .menuButton {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 13%;
  padding-left: 4px;
  padding-right: 4px;
  color: #00B246;
  width: 3em;
  height: 3.5em;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}
.new-header .menuButton:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}
.new-header .menuButton span {
  width: 30px;
  height: 4px;
  background: #131313;
  border-radius: 100px;
  transition: 0.3s ease;
}
.new-header .menuButton.active span {
  background: #00B246;
}
.new-header .menuButton.active span:nth-child(1) {
  transform: translateY(320%) rotate(45deg);
  width: 40px;
}
.new-header .menuButton.active span:nth-child(2) {
  transform: translateX(-20px);
  opacity: 0;
}
.new-header .menuButton.active span:nth-child(3) {
  transform: translateY(-220%) rotate(-45deg);
  width: 40px;
  box-shadow: 0 0 6px #00B246;
}
.new-header__mobile-menu {
  display: none;
  background-color: #fff;
  position: absolute;
  top: 100px;
  right: 0;
  width: 100%;
  padding: 25px;
}
.new-header__mobile-menu ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
}
.new-header__mobile-menu ul li {
  margin-bottom: 15px;
}
.new-header__mobile-menu ul li .phone-link {
  margin-left: 0;
}
.new-header__mobile-menu ul li button.hover-main-color {
  border: 0;
  background-color: unset;
  color: #3d3d3d;
  font-size: 20px;
}
.new-header__mobile-menu ul li a {
  font-size: 20px;
}

.pulse-button:hover {
  transform: scale(1.1); /* Увеличение при наведении */
  background-color: #10af18;
}

.pulse-button:active {
  transform: scale(0.95); /* Небольшое уменьшение при нажатии */
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}
.pulse-button {
  animation: pulse 1.4s infinite; /* Бесконечная пульсация */
}

.button-zj {
  display: inline-block;
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #00B246;
  transition: all 0.2s ease;
}

.button-zj:active {
  transform: scale(0.96);
}

.button-zj:before,
.button-zj:after {
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1000;
  background-repeat: no-repeat;
}

.button-zj:hover:before {
  top: -70%;
  background-image: radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, transparent 20%, #00B246 20%, transparent 30%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, transparent 10%, #00B246 15%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: greentopBubbles 0.6s ease;
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
.button-zj:hover::after {
  bottom: -70%;
  background-image: radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, transparent 10%, #00B246 15%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%), radial-gradient(circle, #00B246 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: greenbottomBubbles 0.6s ease;
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
.abutton {
  color: #000;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.abutton-return-book {
  margin-top: 14px;
  padding: 0.5em 1em;
  color: #00B246;
}

.abutton:active {
  color: #00B246;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

@media (max-width: 768px) {
  .new-header__nav,
  .new-header__actions {
    display: none;
  }
  .new-header__burger {
    display: block;
  }
  .menuButton {
    display: flex !important;
  }
  .new-header__mobile-menu.active {
    display: block;
  }
}
.services {
  padding: 40px 20px;
}
.services .services-container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
.services .service-card {
  padding-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 500px;
  text-align: center;
  transition: transform 0.3s;
}
.services .service-card:hover {
  transform: translateY(-10px);
}
.services .service-card .service-image {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
.services .service-card .service-title {
  margin: 15px 0 10px;
  font-size: 22px;
  color: #333;
}
.services .service-card .service-description {
  padding: 0 15px;
  font-size: 16px;
  color: #666;
}
.services .service-card .service-button {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #00B246;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.services .service-card .service-button:hover {
  background-color: #005321;
}

.mainpicservices .main_title {
  color: #fff;
}

.mainpic-block,
.mainpicservices {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
}

.mainpicservices {
  background-position: center;
  background-image: url(../../img/main/pool.jpg), linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
}

.mt-5 {
  margin-top: 50px;
}

.main_titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.slide-arrows {
  display: flex;
  position: absolute;
  margin-top: 100px;
  left: 50px;
  right: 50px;
  justify-content: space-between;
}

.slide-arrow {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #fff;
  padding: 10px 14px;
  transition: 0.2s;
}

.slide-arrow:hover {
  color: #333333;
  background-color: #fff;
}

.main_mini-title {
  font-size: 20px;
}

.main_title {
  font-family: "bab";
  /* margin-top: 30px; */
  margin-bottom: 80px;
  font-size: 58px;
}

.main_priority {
  width: 40px;
  margin-right: 6px;
}

.container {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

.main-block_img {
  width: 80px;
}

.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  border-bottom: 1px solid #f2f5fa;
}
.about-section__gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
}
.about-section__gallery img {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-container {
  max-width: 800px;
  text-align: justify;
  background: rgba(255, 255, 255, 0.063); /* Semi-transparent overlay */
  padding: 2rem;
  border-radius: 10px;
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 8px rgba(255, 255, 255, 0.2);
  color: #00B246;
}

.about-text {
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.6;
  color: #424242;
  text-align: justify;
}

@media (max-width: 768px) {
  .about-title {
    font-size: 2rem;
  }
  .about-text {
    font-size: 1rem;
  }
}
.services-section {
  padding: 3rem 1rem;
  text-align: center;
  overflow-x: hidden;
}

.services-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #00B246;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.services-grid {
  display: flex;
  gap: 1.5rem;
  padding-bottom: 1rem;
  scroll-snap-type: x mandatory;
  animation: scroll 20s linear infinite;
}
.services-grid:hover {
  animation-play-state: paused; /* Остановка при наведении */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.services-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex: 0 0 400px; /* фиксированная ширина карточки */
  scroll-snap-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.services-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.services-image {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  border-bottom: 2px solid #e6e6e6;
}

.services-card-title {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #00B246;
}

.services-description {
  padding: 0 1.5rem 1.5rem;
  font-size: 1rem;
  color: #424242;
  line-height: 1.6;
}

/* Скрываем полосу прокрутки на WebKit-браузерах */
.services-grid::-webkit-scrollbar {
  display: none;
}

.main-rooms {
  margin-top: 100px;
}

.main-rooms-blocks {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.main-rooms-block {
  margin-top: 70px;
}

.main-rooms-block_img {
  width: 550px;
}

.main-rooms-block_text {
  margin-top: 47px;
  font-size: 26px;
}

.main-services-blocks {
  margin-top: 100px;
  margin-bottom: 100px;
}

.main-services-block {
  margin-top: 100px;
  padding: 40px 57px;
  background-color: #f5f3f0;
  position: relative;
}

.services-block-title {
  color: #00B246;
  font-size: 48px;
  margin-bottom: 62px;
  text-align: center;
}

.underline-title::after {
  position: relative;
  content: "";
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 2px;
  margin-top: 5px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0), #00B246 50% 50%, rgba(255, 255, 255, 0));
}

.underline-title-white::after {
  position: relative;
  content: "";
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 2px;
  margin-top: 5px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0), #ffffff 50% 50%, rgba(255, 255, 255, 0));
}

.main-services-block_img {
  width: 50%;
}

.main-services-block_it {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-services-block_it-block-right {
  margin-left: 44px;
  width: 50%;
}

.main-services-block_it-block-left {
  margin-right: 44px;
  width: 50%;
}

.main-services-block_it-text {
  font-size: 20px;
  line-height: 43px;
  text-align: justify;
  color: #424242;
}

.button-large {
  padding: 27px 90px;
  border: 1px solid #3d3d3d;
  margin-top: 70px;
  cursor: pointer;
  font-size: 22px;
}

.button-right {
  float: right;
}

.button-left {
  float: left;
}

.footer-contacts-us {
  margin-bottom: 140px;
}

.footer-contacts-us_hr {
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.footer-contacts-us_title {
  font-family: "bai";
  font-size: 64px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 70px;
  color: #00B246;
}

.footer-contacts-us_text {
  font-size: 22px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  line-height: 45px;
  color: #424242;
}

footer {
  background-color: #e4e4e4;
  color: #00B246;
  position: relative;
}

footer a {
  color: #00B246;
}

footer a:hover {
  color: #005321;
}

.footer-flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer-logo img {
  width: 170px;
}

.copyright-text {
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
}

.footer-info-block {
  width: 30%;
  display: flex;
  align-items: center;
}

.footer-info-block_ul {
  list-style-type: none;
}

.footer-info-block_ul_li {
  display: flex;
  margin-bottom: 15px;
}

.footer-info-block_ul_li-block {
  display: flex;
  flex-direction: column;
}

.rooms {
  background-image: url(../../img/main/rooms-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.rooms_blocks {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 200px;
}

.rooms_block_imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.rooms_block_rm_img {
  height: 188px;
  padding: 20px;
}

.container-serv {
  max-width: 1455px;
  margin-right: auto;
  margin-left: auto;
}

.serv-block {
  margin-top: 40px;
  margin-bottom: 100px;
}

.serv-block_main_pic {
  width: 100%;
}

.serv-block_main {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.vertical-line-left {
  padding-right: 40px;
  border-right: 2px;
  border-right-style: solid;
  -o-border-image: linear-gradient(to top, #986f38, rgba(0, 0, 0, 0)) 1 100%;
     border-image: linear-gradient(to top, #986f38, rgba(0, 0, 0, 0)) 1 100%;
}

.serv-block_main_text {
  font-size: 28px;
  line-height: 53px;
  text-align: justify;
}

.serv-block_imgs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.block_imgs_img {
  display: inline-block;
  margin-bottom: 60px;
  width: 700px;
}

/* Контейнер формы */
.container-rooms-choosen {
  margin: 2rem auto;
  padding: 2.5rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  max-width: 600px;
}

.container-rooms-choosen__title {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  color: #00B246;
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.guest-info .title-guest-info {
  margin-bottom: 4px;
}
.guest-info .form-group {
  margin-bottom: 6px !important;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.booking-form__list-data {
  list-style-type: disc;
}

.booking-form__list-data__item:not(:last-child) {
  margin-bottom: 6px;
}

.form-group__dates {
  display: flex;
}

.form-group__dates .form-group {
  width: 100%;
}

.form-group label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #34495e;
  font-family: "Roboto", sans-serif;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #34495e;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #3498db;
  outline: none;
}

.form-group select option {
  padding: 0.5rem;
  font-size: 1rem;
}

.button-form-group {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 0.75rem;
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 1.5rem;
}

.button-form-group:hover {
  background-color: #2980b9;
}

/* Стили для секции результатов */
.container-rooms-result {
  border-radius: 12px;
}

.container-rooms-result__title {
  margin-bottom: 2.5rem;
  text-align: center;
  font-size: 2rem;
  color: #00B246;
  font-family: "Roboto", sans-serif;
}

.room-result {
  margin-bottom: 2rem;
  padding: 1.5rem;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.room-result__hr-bottom {
  padding-bottom: 24px;
  border-bottom: 1px solid #34495e;
  margin-bottom: 24px;
}
.room-result__services-room {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}
.room-result__services-room__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.room-result__services-room__item span {
  color: #00B246;
  margin-bottom: 16px;
}
.room-result__description-room {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.room-result__description-room__block {
  width: 70%;
  padding-right: 12px;
}
.room-result__description-room__title {
  color: #00B246;
  margin-bottom: 12px;
  font-size: 20px;
}
.room-result__description-room__text {
  font-size: 16px;
  line-height: 34px;
  text-align: justify;
}
.room-result__additional-services {
  width: 30%;
  border-left: 1px solid #34495e;
  padding-left: 12px;
  list-style-type: none;
}
.room-result__additional-services__title {
  font-weight: bold;
  color: #00B246;
  font-size: 18px;
}
.room-result__additional-services__item:not(:last-child) {
  margin-bottom: 20px;
}

.room-result:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.room-result__type {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #00B246;
  text-align: center;
  font-weight: bold;
}

.room-result__main-images-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.room-result__main-images-block__main-image {
  width: 78%;
}
.room-result__main-images-block__main-image img {
  width: 100%;
}
.room-result__main-images-block__images {
  margin-left: 12px;
  width: 20%;
}
.room-result__main-images-block__images img {
  cursor: pointer;
  width: 100%;
  transition: transform 0.3s ease;
}
.room-result__main-images-block__images img:hover {
  transform: scale(1.01);
}

.room-result__price,
.room-result__guests,
.room-result__amenities,
.room-result__floor,
.room-result__description {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  color: #2c3e50;
  font-family: "Roboto", sans-serif;
}

.room-result__payment-notice {
  color: #e74c3c;
  font-weight: bold;
}

.room-result__description {
  color: #7f8c8d;
  font-style: italic;
}

/* Стиль для кнопки бронирования */
.button-booking {
  display: block;
  background-color: #00B246;
  color: #fff;
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin: 1rem auto 0 auto;
}

.button-booking-small {
  padding: 0.4rem 1rem;
}

.to-book-room {
  font-size: 18px;
  padding: 1rem 2.2rem;
}

.button-booking:hover {
  background-color: #005321;
}

.button-booking:active {
  transition: 0.2s ease-out;
  transform: scale(0.99);
}

.background-floor {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(56, 56, 56, 0.6980392157);
  z-index: 10000;
  display: flex !important;
  justify-content: center;
}

.searching-status-booking {
  text-align: center;
}

.form-simple {
  min-width: 400px;
  max-height: 100%;
  max-width: 500px;
  padding: 40px;
  border-radius: 12px;
  background-color: #fff;
  margin: auto;
}
.form-simple__title {
  text-align: right;
  font-size: 24px;
  color: #00B246;
  margin-bottom: 12px;
}
.form-simple .form-group {
  margin-bottom: 22px;
}
.form-simple .toggleClientForm {
  margin-top: 16px;
}
.form-simple .toggleClientForm span {
  color: #3498db;
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
}
.form-simple .toggleClientForm span:hover {
  color: #2980b9;
}

.booking-form-pos {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.booking-form {
  overflow-y: scroll;
  max-height: 100%;
  padding: 40px;
  border-radius: 12px;
  margin: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.booking-form__guests {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.booking-form__link-cancel {
  color: #d9534f;
  text-decoration: underline;
  font-weight: 600;
}
.booking-form__link-cancel:hover {
  color: #c9302c;
  text-decoration: none;
}

.disabled-date {
  color: gray;
  pointer-events: none;
}

.booking-form .form-group {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.booking-form .form-group label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.booking-form .form-group input,
.booking-form .form-group select,
.booking-form .form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.booking-form .form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.gallery {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Стили для модального окна */
}
.gallery .gallery-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #00B246;
  text-align: center;
}
.gallery .row {
  width: 100%;
  margin: 0 auto;
}
.gallery .row ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.gallery .row ul li {
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.gallery .row ul li img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.3s ease-in-out;
}
.gallery .row ul li img:hover {
  transform: scale(1.05);
}
.gallery .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.gallery .modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}
.gallery .modal-content img {
  width: 100%;
  height: auto;
}
.gallery .modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

.books-container {
  padding: 2rem;
  background-color: #f8f9fa;
}
.books-container .books-title {
  text-align: center;
  font-size: 2rem;
  color: #00B246;
  margin-bottom: 1.5rem;
}
.books-container .books-list {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}
.books-container .books-list .booking-item:not(:first-child) {
  margin-left: 32px;
}
.books-container .books-list .booking-item {
  max-width: 400px;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #00B246;
}
.books-container .books-list .booking-item a {
  display: inline-block;
}
.books-container .books-list .booking-item h3 {
  margin-bottom: 0.5rem;
  color: #00B246;
}
.books-container .books-list .booking-item p {
  margin: 0.3rem 0;
  color: #555;
}
.books-container .books-list .booking-item:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

.booking-item-guest {
  border-bottom: 1px solid #e2e2e2;
}

.slider__main-text {
  color: #00B246 !important;
}

.return-payment-form {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-upload-form {
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.document-upload-form h3 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}
.document-upload-form button {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.document-upload-form button:hover {
  background-color: #0056b3;
}
.document-upload-form form {
  display: flex;
  flex-direction: column;
}
.document-upload-form form div {
  margin-bottom: 15px;
}
.document-upload-form form div label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}
.document-upload-form form div input[type=file] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
.document-upload-form form div input[type=file]:focus {
  outline: none;
  border-color: #007bff;
}
.document-upload-form form button[type=submit] {
  padding: 10px 0;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.document-upload-form form button[type=submit]:hover {
  background-color: #218838;
}

/* Общие стили */
.ourservices {
  padding: 20px;
}
.ourservices .services-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 30px;
  color: #333;
}
.ourservices .services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 0;
  list-style: none;
}
.ourservices .service-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.5s ease;
  opacity: 0; /* Начальная прозрачность */
  transform: translateY(20px); /* Начальное смещение снизу */
}
.ourservices .service-item.visible {
  opacity: 1; /* При появлении становится видимым */
  transform: translateY(0); /* Возвращается в исходное положение */
}
.ourservices .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}
.ourservices .service-item .service-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
  position: relative;
}
.ourservices .service-item .service-title::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 100%;
  height: 1px;
  background-color: #28a745; /* Зеленая линия */
}
.ourservices .service-item .service-description {
  font-size: 16px;
  color: #555;
}

/* Мобильная версия */
@media (max-width: 768px) {
  .ourservices .services-title {
    font-size: 28px;
  }
  .ourservices .service-title {
    font-size: 20px;
  }
  .ourservices .service-description {
    font-size: 14px;
  }
}
/* Очень маленькие экраны (например, смартфоны в портретной ориентации) */
@media (max-width: 480px) {
  .ourservices .services-title {
    font-size: 24px;
  }
  .ourservices .service-item {
    padding: 15px;
  }
  .ourservices .service-title {
    font-size: 18px;
  }
  .ourservices .service-description {
    font-size: 12px;
  }
}
.block-form-login-manual {
  display: flex;
  justify-content: center;
  align-items: center;
}
.block-form-login-manual__block {
  margin-left: 32px;
  width: 500px;
}
.block-form-login-manual__block video {
  width: 90%;
}

.service-page {
  max-width: 1100px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
}
.service-page .title {
  color: #00B246;
  font-size: 32px;
  font-weight: bold;
}
.service-page .undertitle {
  font-size: 18px;
  color: #242424;
}
.service-page .under-line {
  text-decoration: underline;
}
.service-page > *:not(:last-child) {
  margin-bottom: 10px;
}
.service-page .service-main-image img {
  max-height: 550px !important;
}
.service-page .service-image {
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.service-page .service-image img {
  width: 100%;
  height: 100%;
  max-height: 400px;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
.service-page .service-description {
  white-space: pre-wrap;
  font-size: 16px;
}
.service-page .service-gallery {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  gap: 15px;
  width: 100%;
  max-width: 1200px;
  grid-auto-flow: dense;
}

.contacts {
  background: linear-gradient(135deg, #ffffff, #f8f8f8);
  padding: 50px 30px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 50px auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.contacts:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}
.contacts__title {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.contacts__info {
  padding: 35px;
  animation: fadeIn 1s ease-in-out;
}
.contacts__hotel-name {
  font-size: 1.6rem;
  color: #34495e;
  margin-bottom: 20px;
}
.contacts__address, .contacts__phone, .contacts__email {
  font-size: 17px;
  color: #555;
  margin: 15px 0;
}
.contacts a {
  color: #00B246;
  text-decoration: none;
  font-weight: 500;
  display: block;
  transition: color 0.3s, transform 0.3s;
}
.contacts a:hover {
  color: #005321;
  transform: scale(1.05);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*==========  Desktop First Method  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1440px) {
  .room-slider {
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }
  .services-about-list {
    margin-top: 48px;
  }
  .services-about-list-li {
    margin-right: 30px;
    margin-bottom: 45px;
    font-size: 16px;
  }
  .main-reviews {
    margin-top: 45px;
  }
  .main-reviews_review-text {
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }
  .main-reviews_review-img {
    width: 100%;
    margin-left: 0;
  }
  .main-block_img {
    width: 50px;
  }
  .main-block-title {
    margin-left: 50px;
  }
  .main-block-title_text {
    margin-left: 10px;
    font-size: 22px;
  }
  .main-rooms-block {
    margin-top: 30px;
  }
  .main-rooms-block_text {
    margin-top: 20px;
    font-size: 18px;
  }
  .services-about_about {
    margin-top: 40px;
  }
  .main-rooms {
    margin-top: 60px;
  }
  .services-block-title {
    font-size: 42px;
  }
  .main-services-block_it-block-right {
    margin-left: 22px;
  }
  .main-services-block_it-text {
    font-size: 18px;
    line-height: 25px;
  }
  .services-decor-left-img,
  .services-decor-right-img {
    width: 50px;
  }
  .footer-contacts-us_title {
    font-size: 45px;
    margin-top: 16px;
    margin-bottom: 40px;
  }
  .footer-contacts-us_text {
    font-size: 16px;
    width: 80%;
    line-height: 35px;
  }
  .main_mini-title {
    font-size: 15px;
  }
  .main_title {
    font-size: 42px;
  }
  .main_priority {
    width: 20px;
  }
  .booking-form .form-group input,
  .booking-form .form-group select,
  .booking-form .form-group textarea {
    width: 100%;
    padding: 0.35rem;
  }
  .service-page {
    margin-left: 20px;
    margin-right: 20px;
  }
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .block-form-login-manual {
    flex-direction: column-reverse;
  }
  .block-form-login-manual__block {
    margin-top: 24px;
    margin-left: 0;
    width: 90%;
  }
  .block-form-login-manual__block video {
    width: 100%;
  }
  .head-title {
    margin-top: 80px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .container {
    max-width: 98%;
  }
  .login-btn-auth {
    display: inline;
  }
  .lang-switcher {
    margin-right: 12px;
  }
  .search-form {
    gap: 0;
    align-items: normal;
  }
  .container-rooms-result {
    margin: 1rem auto;
  }
  .room-result__description-room {
    display: flex;
    flex-direction: column;
  }
  .room-result__description-room__block {
    width: 100%;
    padding-right: 0;
    border-right: 0;
    border-bottom: 1px solid #34495e;
    padding-bottom: 12px;
    margin-bottom: 22px;
  }
  .room-result__description-room__text {
    line-height: 28px;
  }
  .room-result__additional-services {
    width: 100%;
    border-left: 0;
  }
  .books-container {
    margin-top: 36px;
  }
  .books-container .books-list {
    margin-bottom: 32px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    text-align: center;
  }
  .books-container .books-list .booking-item:not(:first-child) {
    margin-top: 16px;
    margin-left: 0;
  }
  .room-result {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid #f0f0f0;
  }
  .rooms-page-card {
    padding: 5px;
  }
  .services-block-title {
    font-size: 32px;
  }
  .main-services-block_it {
    flex-wrap: wrap;
  }
  .main-services-block_it-block-left {
    margin-right: auto;
    width: auto;
    margin-bottom: 20px;
  }
  .main-services-block {
    padding: 20px 17px;
  }
  .vertical-line-left {
    padding-left: 15px;
  }
  .services-decor-left-img,
  .services-decor-right-img {
    display: none;
  }
  .main-services-block_it-block-right {
    margin-left: 0;
  }
  .main-services-block_it-block-right {
    width: auto;
  }
  .main-services-block_it-text {
    margin-top: 20px;
  }
  .main-services-block_img {
    width: 90%;
  }
  /* services */
  .container-services {
    width: 85%;
  }
  .cservices {
    margin-top: 35px;
  }
  .services-title-text {
    font-size: 28px;
    padding: 30px;
  }
  .cservices-title {
    margin-bottom: 25px;
    font-size: 35px;
  }
  .cservices-block-it,
  .vertical-line {
    margin-left: 25px;
  }
  .cservices-text {
    margin-top: 20px;
    line-height: 30px;
    font-size: 18px;
  }
  /* Rooms */
  .rooms_blocks {
    padding-bottom: 100px;
  }
  .rooms_block_rm_img {
    height: 130px;
    padding: 10px;
  }
  .block_imgs_img {
    width: 100%;
    margin-bottom: 30px;
  }
  .serv-block {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .serv-block_main {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .serv-block_main_text {
    font-size: 20px;
    line-height: 30px;
  }
  .container-rooms-choosen {
    padding: 2rem;
  }
  .search-form {
    flex-direction: column;
    gap: 1rem;
  }
  .form-group {
    flex: 1 1 100%; /* Каждая форма занимает 100% ширины */
    margin-right: 0; /* Убираем отступ справа */
  }
  .button-form-group {
    margin-left: 0;
    margin-right: 0;
    width: 100%; /* Кнопка занимает всю ширину */
  }
  .room-result {
    padding: 1.5rem;
  }
  .room-result__images {
    flex-direction: column;
  }
  .room-result__images img {
    width: 100%;
    margin-bottom: 1rem;
  }
  .booking-form-dates {
    width: 100% !important;
  }
  .footer-info-block {
    width: auto;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .container {
    max-width: 400px;
  }
  .main-block-title {
    margin-left: 15px;
  }
  .main-block-title_text {
    font-size: 18px;
  }
  .main-reviews_review-text {
    line-height: 25px;
  }
  .main-services-block_it-text {
    font-size: 14px;
  }
  .main-services-block_it-text {
    margin-top: 10px;
  }
  .main-services-block {
    margin-top: 40px;
  }
  .footer-contacts-us_text {
    font-size: 14px;
    width: 90%;
    line-height: 20px;
    text-align: justify;
  }
  .copyright-text {
    text-align: center;
    margin-top: 20px;
  }
  .footer-info-block_ul_li {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .footer-contacts-us_title {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .footer-contacts-us {
    margin-bottom: 60px;
  }
  .main-services-blocks {
    margin-bottom: 50px;
  }
  .main-rooms-block_text {
    margin-left: 12px;
  }
  .main-rooms-block_img {
    width: 100%;
  }
  .rooms_block_rm_img {
    height: 100px;
    padding: 5px;
  }
  .serv-block_main_text {
    font-size: 16px;
    line-height: 30px;
  }
  /* Rooms */
  .container-rooms-choosen {
    padding: 1.5rem;
  }
  .room-result__type {
    font-size: 1.25rem;
  }
  .room-result__price,
  .room-result__payment-notice,
  .room-result__guests,
  .room-result__amenities,
  .room-result__floor,
  .room-result__description {
    font-size: 0.9rem;
  }
  .services-grid {
    animation: scroll 10s linear infinite;
  }
  .services-card {
    flex: 0 0 200px; /* фиксированная ширина карточки */
  }
  .services-image {
    height: 150px;
  }
  .services-card-title {
    margin: 0.8rem 0;
  }
  .services-description {
    padding: 0 1rem 1rem;
  }
  .gallery .row ul {
    grid-template-columns: repeat(2, 1fr);
  }
}
/* Custom, iPhone Retina *//*# sourceMappingURL=index.css.map */