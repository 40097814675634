.booking-container {
    position: fixed;
    right: 20px;
    top: 120px;
    z-index: 1000;
  
    .booking-button {
      background-color: #00B246;
      color: #fff;
      border: none;
      border-radius: 50px;
      padding: 15px 20px;
      font-size: 16px;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #19b959;
      }
    }
  
    .booking-form {
      position: fixed;
      right: 20px;
      top: 170px;
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      width: 300px;
  
      h3 {
        margin: 0 0 15px;
        font-size: 18px;
        color: #333;
      }
  
      form {
        display: flex;
        flex-direction: column;
  
        label {
          font-size: 14px;
          margin-bottom: 10px;
  
          input {
            margin-top: 5px;
            padding: 10px;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
        }
  
        button {
          margin-top: 10px;
          background-color: #00B246;
          color: #fff;
          border: none;
          padding: 10px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
  
          &:hover {
            background-color: #19b959;
          }
        }
      }
    }
  }
  