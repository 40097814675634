@charset "UTF-8";
/* MaintenanceMode.css */
.maintenance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Занимает всю высоту экрана */
  background-color: #f8f9fa; /* Светлый фон */
  color: #343a40; /* Темный текст */
  font-family: "Arial", sans-serif; /* Шрифт */
  text-align: center;
}

.maintenance-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #dc3545; /* Красный цвет заголовка */
}

.maintenance-message {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.maintenance-icon {
  font-size: 5rem; /* Размер иконки */
}/*# sourceMappingURL=maintenancemode.css.map */