/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1441px) {
}
@media only screen and (max-width: 1440px) {
  .room-slider {
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }
  .services-about-list {
    margin-top: 48px;
  }
  .services-about-list-li {
    margin-right: 30px;
    margin-bottom: 45px;
    font-size: 16px;
  }
  .main-reviews {
    margin-top: 45px;
  }
  .main-reviews_review-text {
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }
  .main-reviews_review-img {
    width: 100%;
    margin-left: 0;
  }
  .main-block_img {
    width: 50px;
  }
  .main-block-title {
    margin-left: 50px;
  }
  .main-block-title_text {
    margin-left: 10px;
    font-size: 22px;
  }
  .main-rooms-block {
    margin-top: 30px;
  }
  .main-rooms-block_text {
    margin-top: 20px;
    font-size: 18px;
  }
  .services-about_about {
    margin-top: 40px;
  }
  .main-rooms {
    margin-top: 60px;
  }

  .services-block-title {
    font-size: 42px;
  }
  .main-services-block_it-block-right {
    margin-left: 22px;
  }
  .main-services-block_it-text {
    font-size: 18px;
    line-height: 25px;
  }
  .services-decor-left-img,
  .services-decor-right-img {
    width: 50px;
  }
  .footer-contacts-us_title {
    font-size: 45px;
    margin-top: 16px;
    margin-bottom: 40px;
  }
  .footer-contacts-us_text {
    font-size: 16px;
    width: 80%;
    line-height: 35px;
  }
  .main_mini-title {
    font-size: 15px;
  }
  .main_title {
    font-size: 42px;
  }
  .main_priority {
    width: 20px;
  }
  .booking-form .form-group input,
  .booking-form .form-group select,
  .booking-form .form-group textarea {
    width: 100%;
    padding: 0.35rem;
  }
  .service-page {
    margin-left: 20px;
    margin-right: 20px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .block-form-login-manual {
    flex-direction: column-reverse;
    &__block {
      margin-top: 24px;
      margin-left: 0;
      width: 90%;
      video {
        width: 100%;
      }
  
    }
  }
  .head-title {
    margin-top: 80px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .container {
    max-width: 98%;
  }
  .login-btn-auth {
    display: inline;
  }
  .lang-switcher {
    margin-right: 12px;
  }
  .search-form {
    gap: 0;
    align-items: normal;
  }
  .container-rooms-result {
    margin: 1rem auto;
  }
  .room-result {
    &__description-room {
      display: flex;
      flex-direction: column;
      &__block {
        width: 100%;
        padding-right: 0;
        border-right: 0;
        border-bottom: 1px solid #34495e;
        padding-bottom: 12px;
        margin-bottom: 22px;
      }
      &__text {
        line-height: 28px;
      }
    }
      &__additional-services {
        width: 100%;
        border-left: 0;
      }
  }
  .books-container {
    margin-top: 36px;
    .books-list {
      margin-bottom: 32px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      padding: 0;
      text-align: center;
      .booking-item:not(:first-child) {
        margin-top: 16px;
        margin-left: 0;
      }
    }
  }
  .room-result {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid #f0f0f0;
  }
  .rooms-page-card {
    padding: 5px;
  }
  .services-block-title {
    font-size: 32px;
    // margin-bottom: 38px;
  }
  .main-services-block_it {
    flex-wrap: wrap;
  }
  .main-services-block_it-block-left {
    margin-right: auto;
    width: auto;
    margin-bottom: 20px;
  }
  .main-services-block {
    padding: 20px 17px;
  }
  .vertical-line-left {
    padding-left: 15px;
  }
  .services-decor-left-img,
  .services-decor-right-img {
    display: none;
  }
  .main-services-block_it-block-right {
    margin-left: 0;
  }
  .main-services-block_it-block-right {
    width: auto;
  }
  .main-services-block_it-text {
    margin-top: 20px;
  }
  .main-services-block_img {
    width: 90%;
  }
  /* services */
  .container-services {
    width: 85%;
  }

  .cservices {
    margin-top: 35px;
  }
  .services-title-text {
    font-size: 28px;
    padding: 30px;
  }
  .cservices-title {
    margin-bottom: 25px;
    font-size: 35px;
  }
  .cservices-block-it,
  .vertical-line {
    margin-left: 25px;
  }
  .cservices-text {
    margin-top: 20px;
    line-height: 30px;
    font-size: 18px;
  }

  /* Rooms */
  .rooms_blocks {
    padding-bottom: 100px;
  }
  .rooms_block_rm_img {
    height: 130px;
    padding: 10px;
  }
  .block_imgs_img {
    width: 100%;
    margin-bottom: 30px;
  }

  .serv-block {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .serv-block_main {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .serv-block_main_text {
    font-size: 20px;
    line-height: 30px;
  }

  .container-rooms-choosen {
    padding: 2rem;
  }

  .search-form {
    flex-direction: column;
    gap: 1rem;
  }

  .form-group {
    flex: 1 1 100%; /* Каждая форма занимает 100% ширины */
    margin-right: 0; /* Убираем отступ справа */
  }

  .button-form-group {
    margin-left: 0;
    margin-right: 0;
    width: 100%; /* Кнопка занимает всю ширину */
  }

  .room-result {
    padding: 1.5rem;
  }

  .room-result__images {
    flex-direction: column;
  }

  .room-result__images img {
    width: 100%;
    margin-bottom: 1rem;
  }
  .booking-form-dates {
    width: 100% !important;
  }
  .footer-info-block {
    width: auto;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .container {
    max-width: 400px;
  }
  .main-block-title {
    margin-left: 15px;
  }
  .main-block-title_text {
    font-size: 18px;
  }
  .main-reviews_review-text {
    line-height: 25px;
  }
  .main-services-block_it-text {
    font-size: 14px;
  }
  .main-services-block_it-text {
    margin-top: 10px;
  }
  .main-services-block {
    margin-top: 40px;
  }
  .footer-contacts-us_text {
    font-size: 14px;
    width: 90%;
    line-height: 20px;
    text-align: justify;
  }
  .copyright-text {
    text-align: center;
    margin-top: 20px;
  }
  .footer-info-block_ul_li {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .footer-contacts-us_title {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .footer-contacts-us {
    margin-bottom: 60px;
  }
  .main-services-blocks {
    margin-bottom: 50px;
  }

  .main-rooms-block_text {
    margin-left: 12px;
  }
  .main-rooms-block_img {
    width: 100%;
  }
  .rooms_block_rm_img {
    height: 100px;
    padding: 5px;
  }
  .serv-block_main_text {
    font-size: 16px;
    line-height: 30px;
  }


  /* Rooms */
  .container-rooms-choosen {
    padding: 1.5rem;
  }


  .room-result__type {
    font-size: 1.25rem;
  }

  .room-result__price,
  .room-result__payment-notice,
  .room-result__guests,
  .room-result__amenities,
  .room-result__floor,
  .room-result__description {
    font-size: 0.9rem;
  }
  // Services
  .services-grid {
    animation: scroll 10s linear infinite;
  }
  .services-card {
    flex: 0 0 200px; /* фиксированная ширина карточки */
  }
  .services-image {
    height: 150px;
  }
  .services-card-title {
    margin: .8rem 0;
  }
  .services-description {
    padding: 0 1rem 1rem;
  }
  // Services ---!

  // Gallery
  .gallery .row ul {
    grid-template-columns: repeat(2, 1fr); // 2 изображения в ряду
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}
